/* You can add global styles to this file, and also import other style files */
a { cursor: pointer }

.app-container {
    min-height: 100vh;
    overflow: hidden;
}

.btn-delete-user {
    width: 40px;
    text-align: center;
    box-sizing: content-box;
}
@import "../node_modules/ag-grid-community/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/styles/ag-theme-alpine.css";
@import '~@fortawesome/fontawesome-free/css/all.css';

/*breadcrumb theme like facebook*/
.breadcrumb {
    background-color: #b5cbe2;
    border-radius: 0;
    padding: 8px 15px;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 400;
    color: #90949c;
    box-shadow: none;
}


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
h1 {
    font-size: 30px !important;
}
